'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderEntry = void 0;
const $ = require("jquery");
class OrderEntry {
    constructor(ajaxing = false) {
        this.ajaxing = ajaxing;
        let self = this;
        // 読み込み時に配送先選択あれば反映
        $(document).ready(() => {
            if ($('#paramDeliveryType').length > 0) {
                const type = $('#paramDeliveryType').val();
                $('.bulletDeliveryType[value="' + type + '"]').prop('checked', true);
                $('.bulletAssignAddress').hide();
                if (type == 'new') {
                    $('.bulletAssignAddress[data-delivery="form"]').show();
                }
                else if (type == 'past') {
                    $('.bulletAssignAddress[data-delivery="past"]').show();
                }
                else if (type == 'book') {
                    $('.bulletAssignAddress[data-delivery="book"]').show();
                }
            }
        });
        // ご注文者情報を反映する
        $('.trigAssignEntryAddress').click(function () {
            const data = OrderEntry.getCustomerAddress();
            OrderEntry.assignAddress(data);
            $('.bulletAssignAddress').hide();
            $('.bulletAssignAddress[data-delivery="form"]').show();
        });
        // 過去の注文履歴から配達先住所を選ぶ
        $('.trigAssignPastAddress').click(function () {
            $('.bulletAssignAddress').hide();
            $('.bulletAssignAddress[data-delivery="past"]').show();
        });
        // 新しい配送先を入力する
        $('.trigNoAssignAddress').click(function () {
            OrderEntry.noAssignAddress();
            $('.bulletAssignAddress').hide();
            $('.bulletAssignAddress[data-delivery="form"]').show();
            $('.bulletSelectedAddressId').prop('checked', false);
        });
        // アドレス帳から選ぶ
        $('.trigAddressBooks').click(function () {
            $('.bulletAssignAddress').hide();
            $('.bulletAssignAddress[data-delivery="book"]').show();
        });
        // 支払い方法
        $('.trigChangePayment').click(function () {
            const num = $(this).data('num');
            $('.bulletChangePayment').hide();
            $('.bulletChangePayment[data-num="' + num + '"]').show();
        });
        // アプリとバージョン選択肢切り替え
        $('.trigAppCode').click(function () {
            const code = $(this).val();
            console.log(code);
            $('.bulletAppCode').hide();
            $('.bulletAppCode[data-code="' + code + '"]').show();
        });
    }
    static assignAddress(data) {
        $('.bulletAssignCustomerAddress[name="sendto_name"]').val('ご登録住所').focus();
        $('.bulletAssignCustomerAddress[name="delivery_zip1"]').val(data.zip1);
        $('.bulletAssignCustomerAddress[name="delivery_zip2"]').val(data.zip2);
        $('.bulletAssignCustomerAddress[name="delivery_pref_id"]').val(data.pref_id);
        $('.bulletAssignCustomerAddress[name="delivery_city"]').val(data.city);
        $('.bulletAssignCustomerAddress[name="delivery_address"]').val(data.address);
        $('.bulletAssignCustomerAddress[name="delivery_tel1"]').val(data.tel1);
        $('.bulletAssignCustomerAddress[name="delivery_tel2"]').val(data.tel2);
        $('.bulletAssignCustomerAddress[name="delivery_tel3"]').val(data.tel3);
    }
    static noAssignAddress() {
        $('.bulletAssignCustomerAddress[name="sendto_name"]').val('').blur();
        $('.bulletAssignCustomerAddress[name="delivery_zip1"]').val('');
        $('.bulletAssignCustomerAddress[name="delivery_zip2"]').val('');
        $('.bulletAssignCustomerAddress[name="delivery_pref_id"]').val('');
        $('.bulletAssignCustomerAddress[name="delivery_city"]').val('');
        $('.bulletAssignCustomerAddress[name="delivery_address"]').val('');
        $('.bulletAssignCustomerAddress[name="delivery_tel1"]').val('');
        $('.bulletAssignCustomerAddress[name="delivery_tel2"]').val('');
        $('.bulletAssignCustomerAddress[name="delivery_tel3"]').val('');
    }
    static getCustomerAddress() {
        return {
            sei: $('.paramAssignCustomerAddress[name="sei"]').val(),
            mei: $('.paramAssignCustomerAddress[name="mei"]').val(),
            pref_id: $('.paramAssignCustomerAddress[name="pref_id"]').val(),
            city: $('.paramAssignCustomerAddress[name="city"]').val(),
            address: $('.paramAssignCustomerAddress[name="address"]').val(),
            zip1: $('.paramAssignCustomerAddress[name="zip1"]').val(),
            zip2: $('.paramAssignCustomerAddress[name="zip2"]').val(),
            tel1: $('.paramAssignCustomerAddress[name="tel1"]').val(),
            tel2: $('.paramAssignCustomerAddress[name="tel2"]').val(),
            tel3: $('.paramAssignCustomerAddress[name="tel3"]').val()
        };
    }
    ajaxAssignCustomerAddress(hashedId) {
        let self = this;
        self.ajaxing = true;
        const token = $('meta[name="csrf-token"]').attr('content');
        let D = { hashed_id: hashedId };
        $.ajax({
            headers: { 'X-CSRF-TOKEN': token },
            url: '/ajax/get_customer_data',
            type: 'post',
            data: D,
            dataType: 'json',
            beforeSend: function () {
                // 実行中画面
                $('#bulletAjaxingEditting').show();
            },
            success: function (data) {
                OrderEntry.assignAddress(data);
            },
            complete: function () {
                // 実行中画面を消す
                $('#bulletAjaxingEditting').hide();
                self.ajaxing = false;
            }
        });
    }
}
exports.OrderEntry = OrderEntry;
exports.default = OrderEntry;
