'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const $ = require("jquery");
var OrderDelivery;
(function (OrderDelivery) {
    class MyOrderDelivery {
        constructor() {
            let self = this;
            // 過去の配送先から選択するか
            $(document).on('change', '.trigChangeUsePastData', function () {
                const val = $('input.trigChangeUsePastData:checked').val();
                self.viewDeliveryWindow(val);
                self.changeAddressId(val);
            });
            // 過去の配送先から選択するか（読み込み時）
            if ($('.trigChangeUsePastData').length > 0) {
                let val = $('input.trigChangeUsePastData:checked').val();
                if (val == undefined) {
                    val = 1;
                    $('input.trigChangeUsePastData[value="' + val + '"]').prop('checked', true);
                }
                self.viewDeliveryWindow(val);
                self.changeAddressId(val);
            }
            // 過去の配送先を選択
            $(document).on('click', '.trigSelectAddressId', function () {
                const id = $(this).attr('data-id');
                self.setAddressId(id);
                self.viewButton(id);
            });
        }
        viewDeliveryWindow(val) {
            $('.bulletChangeUsePastData').removeClass('selected');
            $('.bulletChangeUsePastData[data-use="' + val + '"]').addClass('selected');
        }
        changeAddressId(val) {
            if (val == "0") {
                $('.bulletSelectAddressId').val('');
            }
            else {
                const selectedId = this.getSelectedAddressId();
                $('.bulletSelectAddressId').val(selectedId);
            }
        }
        setAddressId(id) {
            $('.bulletSelectAddressId').val(id);
        }
        viewButton(id) {
            $('.trigSelectAddressId').attr('data-select', 0);
            $('.trigSelectAddressId[data-id="' + id + '"]').attr('data-select', 1);
        }
        getSelectedAddressId() {
            return $('.trigSelectAddressId[data-select="1"]').attr('data-id');
        }
    }
    OrderDelivery.MyOrderDelivery = MyOrderDelivery;
})(OrderDelivery || (OrderDelivery = {}));
exports.default = OrderDelivery;
