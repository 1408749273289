'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
exports.Hamburgler = void 0;
const $ = require("jquery");
const func_1 = require("../func");
class Hamburgler {
    constructor() {
        this.headerHeight = 0;
        const self = this;
        $(document).ready(function () {
            self.headerHeight = $('#paramHeaderArea').outerHeight();
            if (func_1.default.isTab() || func_1.default.isSP()) {
                $('#bulletHeaderNavi').css({ top: self.headerHeight });
            }
            $('#trigHeaderNavi').click(function (e) {
                e.preventDefault();
                $(this).blur();
                const bullet = $('#bulletHeaderNavi');
                const open = bullet.attr('data-open');
                if (open == "0") {
                    bullet.attr('data-open', 1);
                }
                else {
                    bullet.attr('data-open', 0);
                }
            });
        });
        $(window).resize(() => {
            self.headerHeight = $('#paramHeaderArea').outerHeight();
            if (func_1.default.isTab() || func_1.default.isSP()) {
                $('#bulletHeaderNavi').css({ top: self.headerHeight });
            }
            else {
                $('#bulletHeaderNavi').css({ top: 40 });
            }
        });
    }
}
exports.Hamburgler = Hamburgler;
exports.default = Hamburgler;
