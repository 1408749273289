'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
exports.Modal = void 0;
const $ = require("jquery");
class Modal {
    constructor() {
        $('.trigModal').on('click', (e) => {
            $('.bulletModalState').attr('data-modal', '1');
            const url = $(e.currentTarget).attr('data-img');
            const title = $(e.currentTarget).attr('data-title');
            const description = $(e.currentTarget).attr('data-description');
            $('.bulletModalImage').children('img').attr('src', url);
            $('.bulletModalTitle').text(title);
            $('.bulletModalDescription').html(description);
            $('.bulletModal').fadeIn('slow');
        });
        $('.trigCloseModal').on('click', () => {
            $('.bulletModal').fadeOut('slow');
            $('.bulletModalState').attr('data-modal', '0');
            $('.bulletAnimationPlayState').attr('data-play', 'running');
        });
        $('.trigOverlay').on('click', function (e) {
            if (!$.contains($('.paramModal')[0], e.target)) {
                $(this).fadeOut('slow');
                $('.bulletModalState').attr('data-modal', '0');
                $('.bulletAnimationPlayState').attr('data-play', 'running');
            }
        });
    }
}
exports.Modal = Modal;
exports.default = Modal;
