'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
exports.Guide = void 0;
const $ = require("jquery");
const func_1 = require("../func");
class Guide {
    constructor() {
        this.headerHeight = 0;
        window.addEventListener('load', () => {
            if ($('#bulletGuideNavi').length > 0) {
                const headerHeight = $('#paramHeaderArea').height();
                if (func_1.default.isPC() || func_1.default.isTab()) {
                    $('.bulletGuideNavi').css('top', headerHeight);
                }
            }
        });
    }
}
exports.Guide = Guide;
exports.default = Guide;
