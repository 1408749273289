'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
exports.SlickCarousel = void 0;
const $ = require("jquery");
require("slick-carousel");
class SlickCarousel {
    constructor() {
        // page-top
        if ($('#paramThisIsTopPage').length > 0) {
            $('.bulletSlick').slick({
                infinite: true,
                arrows: false,
                dots: true,
                centerMode: true,
                centerPadding: '20%',
                autoplay: true,
                autoplaySpeed: 5000,
                variableWidth: true,
                responsive: [
                    {
                        breakpoint: 1099,
                        settings: {
                            arrows: true,
                            dots: false,
                            centerPadding: '10%',
                            variableWidth: false,
                            prevArrow: '<div class="myslick-arrow myslick-arrow--left"><i class="fas fa-chevron-left"></i></div>',
                            nextArrow: '<div class="myslick-arrow myslick-arrow--right"><i class="fas fa-chevron-right"></i></div>',
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            arrows: true,
                            dots: false,
                            centerPadding: '5%',
                            variableWidth: false,
                            prevArrow: '<div class="myslick-arrow myslick-arrow--left"><i class="fas fa-chevron-left"></i></div>',
                            nextArrow: '<div class="myslick-arrow myslick-arrow--right"><i class="fas fa-chevron-right"></i></div>',
                        }
                    }
                ]
            });
        }
        // 商品スライドショー
        if ($('#paramThisPageHasItemShow').length > 0) {
            $('.bulletSlickItemShow').slick({
                infinite: true,
                arrows: false,
                dots: false,
                centerMode: true,
                centerPadding: '10%',
                autoplay: true,
                autoplaySpeed: 5000,
                slidesToShow: 5,
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            centerPadding: '10%',
                            slidesToShow: 3,
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            centerPadding: '5%',
                            slidesToShow: 2,
                        }
                    }
                ]
            });
        }
    }
}
exports.SlickCarousel = SlickCarousel;
exports.default = SlickCarousel;
