'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
exports.Password = void 0;
const $ = require("jquery");
class Password {
    constructor() {
        let self = this;
        $(document).on('click', '.trigToggleSwitch', function (e) {
            $(this).next().slideToggle(200);
        });
        // パスワードを見えるようにする
        $('.trigShowPassword').click(() => {
            $('.valueShowPassword').val(1);
            $('.paramShowPassword').attr("data-show", 1);
            $('.bulletShowPassword').prop('type', 'text').focus();
        });
        // パスワードを見えないようにする
        $('.trigHidePassword').click(() => {
            $('.valueShowPassword').val(0);
            $('.paramShowPassword').attr("data-show", 0);
            $('.bulletShowPassword').prop('type', 'password').focus();
        });
        // パスワードの強度判定
        $('.trigPasswordLevelMypage').keyup(function () {
            const val = $(this).val();
            var target = document.getElementById('PasswordLevel');
            if (val.length == 0) {
                target.setAttribute('style', 'visibility: hidden;');
            }
            else {
                target.setAttribute('style', 'visibility: visible;');
            }
            const level = Password.getPasswordLevel(val);
            $('.bulletPasswordLevel').attr("data-level", level);
        });
        // パスワードの強度判定
        $('.trigPasswordLevelCommon').keyup(function () {
            const val = $(this).val();
            var target = document.getElementById('PasswordLevel');
            if (val.length == 0) {
                target.setAttribute('style', 'visibility: hidden;');
            }
            else {
                target.setAttribute('style', 'visibility: visible;');
            }
            const level = Password.getPasswordLevel(val);
            $('.bulletPasswordLevel').attr("data-level", level);
        });
        // パスワードの強度判定（ページ読み込み時）
        if ($('.trigPasswordLevel').length > 0) {
            const val = $('.trigPasswordLevel').val();
            const level = Password.getPasswordLevel(val);
            $('.bulletPasswordLevel').attr("data-level", level);
        }
    }
    static getPasswordLevel(val) {
        let level = 0;
        let charaPoint = Password.getCharaPoint(val);
        let lengthPoint = Password.getLengthPoint(val);
        switch (charaPoint * lengthPoint) {
            case 16:
                level = 4;
                break;
            case 12:
            case 9:
                level = 3;
                break;
            case 8:
            case 6:
                level = 2;
                break;
            case 4:
            case 3:
            case 2:
            case 1:
                level = 1;
                break;
        }
        return level;
    }
    static getCharaPoint(val) {
        let point = 0;
        if (val.match('[a-z]+')) {
            point++;
        }
        if (val.match('[A-Z]+')) {
            point++;
        }
        if (val.match('[0-9]+')) {
            point++;
        }
        if (val.match('[^a-zA-Z0-9]+')) {
            point++;
        }
        return point;
    }
    static getLengthPoint(val) {
        let point = 0;
        if (val.length >= 12) {
            point = 4;
        }
        else if (val.length >= 8) {
            point = 3;
        }
        else if (val.length >= 4) {
            point = 2;
        }
        else if (val.length >= 1) {
            point = 1;
        }
        else {
            point = 0;
        }
        return point;
    }
}
exports.Password = Password;
exports.default = Password;
