'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
exports.Development = void 0;
const $ = require("jquery");
const func_1 = require("../func");
class Development {
    constructor() {
        this.data = {
            sei: '陰山',
            mei: '章人',
            sei_kana: 'カゲヤマ',
            mei_kana: 'アキト',
            pref_id: '28',
            city: '加古郡稲美町',
            address: '国安290-9',
            zip1: '675',
            zip2: '1114',
            tel1: '080',
            tel2: '1234',
            tel3: '5678',
            email: 'a.kageyama' + func_1.default.getRandomInt(1, 99999999) + '@nic-lab.jp',
            password: 'ABCDabcd@1234',
            tel: '08012345678',
            body: '吾輩わがはいは猫である。名前はまだ無い。\n' +
                '　どこで生れたかとんと見当けんとうがつかぬ。',
        };
        $('#developmentAddress').click(() => {
            this.setCustomerAddress();
        });
        $('#developmentContact').click(() => {
            this.setCustomerContact();
        });
        $('#developmentRequestSample').click(() => {
            this.setCustomerRequestSample();
        });
    }
    ;
    setCustomerAddress() {
        $('.paramAssignCustomerAddress[name="sei"]').val(this.data.sei);
        $('.paramAssignCustomerAddress[name="mei"]').val(this.data.mei);
        $('.paramAssignCustomerAddress[name="sei_kana"]').val(this.data.sei_kana);
        $('.paramAssignCustomerAddress[name="mei_kana"]').val(this.data.mei_kana);
        $('.paramAssignCustomerAddress[name="pref_id"]').val(this.data.pref_id);
        $('.paramAssignCustomerAddress[name="city"]').val(this.data.city);
        $('.paramAssignCustomerAddress[name="address"]').val(this.data.address);
        $('.paramAssignCustomerAddress[name="zip1"]').val(this.data.zip1);
        $('.paramAssignCustomerAddress[name="zip2"]').val(this.data.zip2);
        $('.paramAssignCustomerAddress[name="tel1"]').val(this.data.tel1);
        $('.paramAssignCustomerAddress[name="tel2"]').val(this.data.tel2);
        $('.paramAssignCustomerAddress[name="tel3"]').val(this.data.tel3);
        $('.paramAssignCustomerAddress[name="email"]').val(this.data.email);
        $('.paramAssignCustomerAddress[name="new_password"]').val(this.data.password);
    }
    setCustomerContact() {
        $('.paramAssignContact[name="sei"]').val(this.data.sei);
        $('.paramAssignContact[name="mei"]').val(this.data.mei);
        $('.paramAssignContact[name="tel"]').val(this.data.tel);
        $('.paramAssignContact[name="email"]').val(this.data.email);
        $('.paramAssignContact[name="email_confirmation"]').val(this.data.email);
        $('.paramAssignContact[name="body"]').val(this.data.body);
    }
    setCustomerRequestSample() {
        $('.paramAssignContact[name="sei"]').val(this.data.sei);
        $('.paramAssignContact[name="mei"]').val(this.data.mei);
        $('.paramAssignContact[name="sei_kana"]').val(this.data.sei_kana);
        $('.paramAssignContact[name="mei_kana"]').val(this.data.mei_kana);
        $('.paramAssignContact[name="pref_id"]').val(this.data.pref_id);
        $('.paramAssignContact[name="city"]').val(this.data.city);
        $('.paramAssignContact[name="address"]').val(this.data.address);
        $('.paramAssignContact[name="zip1"]').val(this.data.zip1);
        $('.paramAssignContact[name="zip2"]').val(this.data.zip2);
        $('.paramAssignContact[name="tel"]').val(this.data.tel);
        $('.paramAssignContact[name="email"]').val(this.data.email);
        $('.paramAssignContact[name="email_confirmation"]').val(this.data.email);
    }
}
exports.Development = Development;
exports.default = Development;
