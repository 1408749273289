'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const $ = require("jquery");
const development_1 = require("./require/development/development");
const smooth_scroll_1 = require("./require/common/smooth-scroll");
const slick_carousel_1 = require("./require/common/slick-carousel");
const hamburgler_1 = require("./require/common/hamburgler");
const header_1 = require("./require/common/header");
const fukidashi_1 = require("./require/common/fukidashi");
// import UserPanel        from './require/common/user_panel';
const password_1 = require("./require/password");
const upload_1 = require("./require/upload");
// import Pricing          from './require/common/pricing';
const faq_1 = require("./require/common/faq");
const guide_1 = require("./require/common/guide");
const fixed_support_1 = require("./require/common/fixed-support");
const modal_1 = require("./require/common/modal");
const example_slide_1 = require("./require/common/example-slide");
const order_1 = require("./require/order/order");
const entry_1 = require("./require/order/entry");
const delivery_1 = require("./require/order/delivery");
const survey_1 = require("./require/order/survey");
const contact_1 = require("./require/common/contact");
$(() => {
    new smooth_scroll_1.default();
    new slick_carousel_1.default();
    new hamburgler_1.default();
    new header_1.default();
    new fukidashi_1.default();
    new fixed_support_1.default();
    new modal_1.default();
    new example_slide_1.default();
    // new UserPanel.MyUserPanel();
    new password_1.default();
    new upload_1.default.MyUpload();
    // new Pricing.MyPricing();
    new faq_1.default();
    new guide_1.default();
    new order_1.default.MyOrder();
    new entry_1.default();
    new delivery_1.default.MyOrderDelivery();
    new survey_1.default.MyOrderSurvey();
    new contact_1.default;
    new development_1.default();
    // $('.draggable').draggable();
});
