'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
exports.Faq = void 0;
const $ = require("jquery");
const func_1 = require("../func");
class Faq {
    constructor() {
        this.jsonData = {};
        let self = this;
        $('.trigFaqFlag').on('click', (e) => {
            const self = e.currentTarget;
            const isOpen = $(self).attr('data-open');
            if (isOpen == 'false') {
                $(self).attr('data-open', 'true');
                $(self).next().slideDown();
            }
            else {
                $(self).attr('data-open', 'false');
                $(self).next().slideUp();
            }
        });
        // FAQページ
        if ($('#paramThisIsFaqPage').length > 0) {
            // Json データを取得
            if ($('#paramJsonData').length > 0) {
                this.jsonData = func_1.default.getJsonData('paramJsonData');
                console.log(this.jsonData);
            }
        }
        // 検索
        $('#trigSearchFaq').click(() => {
            const text = $('#paramSearchFaq').val();
            const words = func_1.default.getWords(text);
            if (words.length == 0) {
                $('.bulletSearchFaq').show();
                return false;
            }
            const faqIds = self.getHitFaqId(words, true);
            $('.bulletSearchFaq').hide();
            for (let i = 0; i < faqIds.length; i++) {
                const faqId = faqIds[i];
                $('.bulletSearchFaq[data-id="' + faqId + '"]').show();
            }
        });
        // 検索
        $('#paramSearchFaq').keyup(() => {
            const text = $('#paramSearchFaq').val();
            const words = func_1.default.getWords(text);
            if (words.length == 0) {
                $('.bulletSearchFaq').show();
                return false;
            }
            const faqIds = self.getHitFaqId(words, true);
            $('.bulletSearchFaq').hide();
            for (let i = 0; i < faqIds.length; i++) {
                const faqId = faqIds[i];
                $('.bulletSearchFaq[data-id="' + faqId + '"]').show();
            }
        });
    }
    getHitFaqId(words, flagDevelop = false) {
        let self = this;
        let hasCurrent = [], hasOld = [], duplicates = [];
        let word, q, a, categories;
        Object.keys(words).forEach(function (i) {
            word = words[Number(i)];
            let ary = [];
            Object.keys(self.jsonData).forEach(function (j) {
                const faqId = j;
                q = self.jsonData[faqId].q;
                if (q.indexOf(word) >= 0) {
                    ary.push(faqId);
                    return;
                }
                a = self.jsonData[faqId].a;
                if (a.indexOf(word) >= 0) {
                    ary.push(faqId);
                    return;
                }
                categories = self.jsonData[faqId].categories;
                if (self.hasWord(word, categories)) {
                    ary.push(faqId);
                }
            });
            hasCurrent = ary;
            if (hasOld.length == 0) {
                duplicates = hasCurrent;
                hasOld = hasCurrent;
                hasCurrent = [];
            }
            else if (hasOld.length > 0) {
                duplicates = func_1.default.getArraysIntersect(hasCurrent, hasOld);
                hasOld = duplicates;
                // hasCurrent = [];
            }
            if (flagDevelop) {
                console.log({ hasCurrent: hasCurrent, hasOld: hasOld, duplicates: duplicates });
            }
        });
        return func_1.default.getArrayUnique(duplicates);
    }
    hasWord(needle, haystacks) {
        let has = false;
        Object.keys(haystacks).forEach(function (i) {
            const haystack = String(haystacks[i]);
            if (haystack.indexOf(needle) >= 0) {
                has = true;
                return;
            }
        });
        return has;
    }
}
exports.Faq = Faq;
exports.default = Faq;
