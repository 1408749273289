'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const $ = require("jquery");
class Func {
    static getOffsetTop(e) {
        const rect = e.getBoundingClientRect();
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return rect.top + scrollTop;
    }
    static sum(numbers) {
        let sum = 0;
        numbers.forEach(function (num) {
            sum += num;
        });
        return sum;
    }
    static getExtention(filename) {
        let types = filename.split('.');
        return types[types.length - 1].toLowerCase();
    }
    static numberFormat(number) {
        return number.toString().replace(/([0-9]+?)(?=(?:[0-9]{3})+$)/g, '$1,');
    }
    static formatDecimalToText(number) {
        let text = '';
        if (number.toString().indexOf('.') != 0) {
            const a = number.toString().split('.');
            if (a[1] == undefined) {
                a[1] = "0";
            }
            text = '<span class="decimal-parent">' + a[0] + '</span><span class="decimal-child">.' + a[1] + '</span>';
        }
        else {
            text = '<span class="decimal-parent">' + number + '</span><span class="decimal-child">.0</span>';
        }
        return text;
    }
    static unitFormat(number, unit = "B") {
        let str = number;
        if (number > 1 * 1000 * 1000 * 1000) {
            str = Func.numberFormat((number / 1000 / 1000 / 1000).toFixed(2)) + "G";
        }
        else if (number > 1 * 1000 * 1000) {
            str = Func.numberFormat((number / 1000 / 1000).toFixed(2)) + "M";
        }
        else if (number > 1 * 1000) {
            str = Func.numberFormat((number / 1000).toFixed(2)) + "K";
        }
        return str + unit;
    }
    static inArray(needle, haystack) {
        let result = false;
        Object.keys(haystack).forEach(function (i) {
            if (needle == haystack[Number(i)]) {
                result = true;
            }
        });
        return result;
    }
    static dateFormat(date, format = 'Y-m-d H:i:s') {
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDay();
        const hour = date.getHours();
        const minutes = date.getMinutes();
        const second = date.getSeconds();
        return format.replace('Y', year)
            .replace('m', Func.sprintf(month, 2))
            .replace('d', Func.sprintf(day, 2))
            .replace('n', month)
            .replace('j', day)
            .replace('H', hour)
            .replace('i', minutes)
            .replace('s', second);
    }
    /**
     * @param number
     * @param number
     * @return string
     */
    static sprintf(num, len) {
        if (String(num).length >= len) {
            return String(num);
        }
        else {
            const zeros = len - String(num).length;
            let str = "";
            for (let i = 0; i < zeros; i++) {
                str += "0";
            }
            return str + String(num);
        }
    }
    static smoothScroll(target, flagAnimation, speed = 500) {
        if (flagAnimation === true) {
            var speed = speed | 500;
            $("html, body").animate({ scrollTop: target }, speed, "swing");
        }
        else {
            var speed = 0;
            $("html, body").animate({ scrollTop: target }, speed, "linear");
        }
        return false;
    }
    static getElementPosition(idName, minus = 0) {
        let pos = Number($(idName).offset().top);
        return pos - minus;
    }
    static number(val) {
        val = val.replace(/[^0-9]/g, '');
        if (isNaN(val)) {
            return 0;
        }
        else {
            return Number(val);
        }
    }
    static insertToTextarea(selector, word) {
        let textarea = document.querySelector(selector);
        let sentence = textarea.value;
        const len = sentence.length;
        const pos = textarea.selectionStart;
        const before = sentence.substr(0, pos);
        const after = sentence.substr(pos, len);
        sentence = before + word + after;
        textarea.value = sentence;
        const endPosition = pos + word.replace(/\r/g, '').length;
        textarea.setSelectionRange(endPosition, endPosition);
    }
    static isLarge(sizeId) {
        let result = false;
        switch (sizeId) {
            case 'B0':
            case 'B1':
            case 'B2':
            case 'B3':
            case 'A0':
            case 'A1':
            case 'A2':
                result = true;
                break;
        }
        return result;
    }
    static getSizeCategory(sizeId) {
        let category = '';
        if (sizeId == 'CD' || sizeId == 'NM') {
            category = 'card';
        }
        else {
            category = sizeId.substr(0, 1);
        }
        return category;
    }
    static getSizeType(sizeId) {
        let type = '';
        if (sizeId == 'CD' || sizeId == 'NM') {
            type = 'special';
        }
        else if (Func.isLarge(sizeId)) {
            type = 'large';
        }
        else {
            type = 'small';
        }
        return type;
    }
    static isSP() {
        const w = window.innerWidth;
        return w <= 480;
    }
    static isTab() {
        const w = window.innerWidth;
        return w >= 481 && w <= 1024;
    }
    static isPC() {
        const w = window.innerWidth;
        return w >= 1025;
    }
    static getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
    }
    // テキストを分割して配列を取得
    static getWords(str) {
        let ary = [];
        str = str.replace(/　/g, ' ');
        if (str.indexOf(' ')) {
            ary = str.split(' ');
            ary = ary.filter(k => k);
        }
        else if (str) {
            ary = [str];
        }
        return ary;
    }
    // Json データを取得
    static getJsonData(id) {
        let data = {};
        if ($('#' + id).length > 0) {
            const elem = document.getElementById(id);
            if (elem) {
                data = JSON.parse(elem.textContent);
            }
        }
        return data;
    }
    // 2つの配列から共通要素を取得
    static getArraysIntersect(ary1, ary2) {
        return [...ary1, ...ary2].filter(item => ary1.includes(item) && ary2.includes(item));
    }
    // 重複を削除
    static getArrayUnique([...array]) {
        return array.filter((value, index, self) => self.indexOf(value) === index);
    }
}
exports.default = Func;
