'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
exports.Contact = void 0;
const $ = require("jquery");
const func_1 = require("../func");
class Contact {
    constructor() {
        let self = this;
        // 初回読込時
        if ($('#ThisIsFirstAccess').length > 0 && $('#paramHasError').length == 0) {
            // contact 初回読込時
            if ($('#ThisIsContactPage').length > 0) {
                // users 取得
                const data = func_1.default.getJsonData('paramUserData');
                // console.log(data);
                // あればデータを代入
                if (data !== null) {
                    this.setUserToContact(data);
                }
            }
            // request_sample 初回読込時
            if ($('#ThisIsRequestSamplePage').length > 0) {
                // users 取得
                const data = func_1.default.getJsonData('paramUserData');
                // console.log(data);
                // あればデータを代入
                if (data !== null) {
                    this.setUserToContact(data);
                }
            }
        }
    }
    setUserToContact(data) {
        $('.paramAssignContact[name="sei"]').val(data.sei);
        $('.paramAssignContact[name="mei"]').val(data.mei);
        $('.paramAssignContact[name="tel"]').val(data.tel);
        $('.paramAssignContact[name="email"]').val(data.email);
        $('.paramAssignContact[name="email_confirmation"]').val(data.email);
    }
    setUserToRequestSample(data) {
        $('.paramAssignContact[name="sei"]').val(data.sei);
        $('.paramAssignContact[name="mei"]').val(data.mei);
        $('.paramAssignContact[name="sei_kana"]').val(data.sei_kana);
        $('.paramAssignContact[name="mei_kana"]').val(data.mei_kana);
        $('.paramAssignContact[name="pref_id"]').val(data.pref_id);
        $('.paramAssignContact[name="city"]').val(data.city);
        $('.paramAssignContact[name="address"]').val(data.address);
        $('.paramAssignContact[name="zip1"]').val(data.zip1);
        $('.paramAssignContact[name="zip2"]').val(data.zip2);
        $('.paramAssignContact[name="tel"]').val(data.tel);
        $('.paramAssignContact[name="email"]').val(data.email);
        $('.paramAssignContact[name="email_confirmation"]').val(data.email);
    }
}
exports.Contact = Contact;
exports.default = Contact;
