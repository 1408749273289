'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
exports.Header = void 0;
const $ = require("jquery");
class Header {
    constructor() {
        this.headerHeight = 0;
        const self = this;
        $(document).ready(function () {
            self.changeHeight();
        });
        $(window).resize(function () {
            self.changeHeight();
        });
    }
    changeHeight() {
        this.headerHeight = $('#paramHeaderArea').outerHeight();
        $('#bulletHeaderArea').css({ paddingTop: this.headerHeight });
    }
}
exports.Header = Header;
exports.default = Header;
