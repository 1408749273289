'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
exports.FixedSupport = void 0;
const $ = require("jquery");
const func_1 = require("../func");
class FixedSupport {
    constructor() {
        $(window).on('load orientationchange resize', () => {
            if (func_1.default.isTab() || func_1.default.isSP()) {
                this.moveTabSp();
                //console.log('PCでない');
            }
            else {
                this.movePc();
                //console.log('PC');
            }
        });
    }
    moveTabSp() {
        $('.trigFixedSupport').on('click', (e) => {
            const isActive = $('.paramFixedSupport').attr('data-active');
            if (isActive === 'false') {
                $('.bulletFixedSupportBlock').attr('data-active', 'true');
                $('.bulletFixedSupportArea').attr('data-active', 'true');
            }
            else {
                $('.bulletFixedSupportBlock').attr('data-active', 'false');
                $('.bulletFixedSupportArea').attr('data-active', 'false');
            }
        });
    }
    movePc() {
        $('.trigFixedSupport').on('mouseover', (e) => {
            $('.bulletFixedSupportBlock').attr('data-active', 'true');
            $('.bulletFixedSupportArea').attr('data-active', 'true');
        });
        $('.trigFixedSupport').on('mouseleave', (e) => {
            $('.bulletFixedSupportBlock').attr('data-active', 'false');
            $('.bulletFixedSupportArea').attr('data-active', 'false');
        });
    }
}
exports.FixedSupport = FixedSupport;
exports.default = FixedSupport;
