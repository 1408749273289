'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExampleSlide = void 0;
const $ = require("jquery");
class ExampleSlide {
    constructor() {
        $('.trigAnimationPlayState').on('mouseover', (e) => {
            if ($('.paramAnimationPlayState').attr('data-play') == 'running') {
                $('.bulletAnimationPlayState').attr('data-play', 'paused');
            }
        });
        $('.trigAnimationPlayState').on('mouseleave', (e) => {
            if ($('.paramModalState').attr('data-modal') === '0' && $('.paramAnimationPlayState').attr('data-play') == 'paused') {
                $('.bulletAnimationPlayState').attr('data-play', 'running');
            }
        });
    }
}
exports.ExampleSlide = ExampleSlide;
exports.default = ExampleSlide;
