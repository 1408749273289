'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const $ = require("jquery");
var OrderSurvey;
(function (OrderSurvey) {
    class MyOrderSurvey {
        constructor(scrolled = 0) {
            this.scrolled = scrolled;
            let self = this;
            $('.trigAnswerScore').bind("click touchstart", function () {
                const questionId = $(this).attr("data-id");
                const score = $(this).attr("data-score");
                $('.paramAnswerScore[data-id="' + questionId + '"]').val(score);
                $('.trigAnswerScore[data-id="' + questionId + '"]').removeClass('checked');
                $(this).addClass('checked');
            });
            $('#trigStartSurvey').bind("click touchstart", function () {
                self.scrolled = Number($(window).height());
                self.showNextBox(1);
                $(this).hide();
            });
            $('.trigSurveyBox').bind("click touchstart", function () {
                const number = Number($(this).attr("data-number"));
                self.showNextBox(number + 1);
            });
        }
        showNextBox(num) {
            let target = $('.bulletSurveyBox[data-number="' + num + '"]');
            const view = target.attr("data-view");
            if (view == "0") {
                target.attr("data-view", "1").show();
                target.animate({ marginLeft: 0 }, { duration: 600, easing: "swing" });
                this.scrolled += Number(target.height());
                $('html, body').animate({ scrollTop: this.scrolled }, { duration: 500, easing: "swing" });
            }
            if (target.length == 0) {
                $('#paramSubmitButton').show();
            }
        }
    }
    OrderSurvey.MyOrderSurvey = MyOrderSurvey;
})(OrderSurvey || (OrderSurvey = {}));
exports.default = OrderSurvey;
