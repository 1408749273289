'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const $ = require("jquery");
const func_1 = require("./func");
var Upload;
(function (Upload) {
    class MyUpload {
        constructor(ajaxing = false, name = "", fileCount = 0) {
            this.ajaxing = ajaxing;
            this.name = name;
            this.fileCount = fileCount;
            this.sizeLimits = {
                icon: 10 * 1000 * 1000,
                item_icon: 10 * 1000 * 1000,
                item_kamp: 100 * 1000 * 1000,
                pagemeta: 5 * 1000 * 1000,
                item_upload_data: 1000 * 1000 * 1000,
                reply_temp_data: 1000 * 1000 * 1000,
            };
            this.extensionLimits = {
                icon: ['jpg', 'jpeg', 'png', 'gif'],
                item_icon: ['jpg', 'jpeg', 'png', 'gif'],
                item_kamp: ['pdf'],
                pagemeta: ['xls', 'xlsx'],
                item_upload_data: ['jpg', 'jpeg', 'png', 'zip', 'rar', 'ai', 'eps', 'pdf', 'psd', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'],
                reply_temp_data: ['jpg', 'jpeg', 'png', 'zip', 'rar', 'ai', 'eps', 'pdf', 'psd', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'],
            };
            this.ext = 'jpg';
            let self = this;
            // ドラッグドロップからの入力 〜マウス押したまま動かしてきた時〜
            $(document)
                .on('drop', '.trigAjaxingDrag', function (e) {
                // false を返してデフォルトの処理を実行しないようにする
                return false;
            })
                .on("dragenter", '.trigAjaxingDrag', function () {
                self.name = $(this).attr('data-name');
                // 該当のドロップエリアを表示
                $('.bulletAjaxingDrag[data-name="' + self.name + '"]').show();
                // console.log(self.name);
                // false を返してデフォルトの処理を実行しないようにする
                return false;
            })
                .on("dragover", '.trigAjaxingDrag', function () {
                // false を返してデフォルトの処理を実行しないようにする
                return false;
            })
                .on("dragleave", '.trigAjaxingDrag', function () {
                // false を返してデフォルトの処理を実行しないようにする
                return false;
            });
            // ドラッグドロップからの入力 〜マウス離した時〜
            $(document)
                .on('drop', '.trigAjaxingDrop', function (event) {
                // 全てのドロップエリアを表示
                $('.trigAjaxingDrop').hide();
                // ドラッグされたファイル情報を取得
                const dragEvent = event.originalEvent, dataTransfer = dragEvent.dataTransfer, files = dataTransfer.files;
                self.fileCount = files.length;
                const bulletFakeInput = '.bulletFakeInput[data-name="' + self.name + '"]';
                const type = $(bulletFakeInput).attr("data-type");
                const resizeSize = $(bulletFakeInput).attr("data-size");
                for (let i = 0; i < self.fileCount; i++) {
                    const file = files[i];
                    const checked = self.checkUploadFile(file, type, i + 1);
                    if (checked.result) {
                        let flagFinish = false;
                        if (self.fileCount == i + 1) {
                            flagFinish = true;
                        }
                        self.uploadFile(file, type, resizeSize, flagFinish);
                    }
                    else {
                        alert(checked.errorMessage);
                        return false;
                    }
                }
                // false を返してデフォルトの処理を実行しないようにする
                return false;
            })
                .on("dragenter", '.trigAjaxingDrop', function () {
                // false を返してデフォルトの処理を実行しないようにする
                return false;
            })
                .on("dragover", '.trigAjaxingDrop', function () {
                // false を返してデフォルトの処理を実行しないようにする
                return false;
            })
                .on("dragleave", '.trigAjaxingDrop', function () {
                $(this).hide();
                // false を返してデフォルトの処理を実行しないようにする
                return false;
            });
            // アップロードした画像を削除
            $(document).on('click', '.trigRemoveImage', function () {
                const targetName = $(this).attr('data-name');
                const uploadId = $(this).attr('data-id');
                self.removeImage(targetName, uploadId);
                return false;
            });
            // アップロードしたファイルを削除
            $(document).on('click', '.trigRemoveFile', function () {
                const targetName = $(this).attr('data-name');
                const uploadId = $(this).attr('data-id');
                self.removeFile(targetName, uploadId);
                return false;
            });
        }
        checkUploadFile(file, type, i = 1) {
            let result = true;
            let errorMessage = "";
            if (file.length == 0) {
                result = false;
                errorMessage = "ファイルがありません";
            }
            else {
                this.ext = func_1.default.getExtention(file.name);
                // let extensionLimits = this.extensionLimits[type];
                let extensionLimits = this.extensionLimits[this.name];
                // let sizeLimits = this.sizeLimits[type];
                let sizeLimits = this.sizeLimits[this.name];
                if (!func_1.default.inArray(this.ext, extensionLimits)) {
                    result = false;
                    errorMessage = i + "th \"" + this.ext + "\" is invalid!\n使用できる拡張子は " + extensionLimits.join("、") + " です";
                }
                else if (file.size > sizeLimits) {
                    result = false;
                    errorMessage = i + "th file size \"" + func_1.default.unitFormat(file.size) + "\" is too large!\nファイルサイズは " + func_1.default.unitFormat(sizeLimits) + " 以内にして下さい";
                }
            }
            return {
                result: result,
                errorMessage: errorMessage
            };
        }
        uploadFile(file, type, resizeSize, flagFinish = false) {
            let fd = new FormData();
            fd.append("file", file);
            fd.append('ext', this.ext);
            fd.append('name', file.name);
            fd.append('type', type);
            fd.append('size', file.size);
            fd.append('resize_size', resizeSize);
            fd.append('input_name', this.name);
            switch (type) {
                case 'image':
                    this.ajaxUploadImage(fd);
                    break;
                case 'images':
                    this.ajaxUploadImages(fd, flagFinish);
                    break;
                case 'file':
                    this.ajaxUploadFile(fd);
                    break;
                case 'files':
                    this.ajaxUploadFiles(fd, flagFinish);
                    break;
            }
        }
        ajaxUploadImage(fd) {
            let self = this;
            self.ajaxing = true;
            const token = $('meta[name="csrf-token"]').attr('content');
            $.ajax({
                headers: { 'X-CSRF-TOKEN': token },
                url: '/ajax/upload_image',
                type: 'post',
                data: fd,
                dataType: 'json',
                cache: false,
                processData: false,
                contentType: false,
                beforeSend: function () {
                    // 実行中画面
                    $('#bulletAjaxingDragging').show();
                },
                success: function (data) {
                    // console.log(data);
                    self.viewUploadedImage(data);
                },
                complete: function () {
                    // 実行中画面を消す
                    $('#bulletAjaxingDragging').hide();
                    self.ajaxing = false;
                }
            });
        }
        ajaxUploadImages(fd, flagFinish) {
            let self = this;
            self.ajaxing = true;
            const token = $('meta[name="csrf-token"]').attr('content');
            $.ajax({
                headers: { 'X-CSRF-TOKEN': token },
                url: '/ajax/upload_image',
                type: 'post',
                data: fd,
                dataType: 'json',
                cache: false,
                processData: false,
                contentType: false,
                beforeSend: function () {
                    // 実行中画面
                    $('#bulletAjaxingDragging').show();
                },
                success: function (data) {
                    // console.log(data);
                    self.viewUploadedImages(data);
                },
                complete: function () {
                    if (flagFinish) {
                        // 実行中画面を消す
                        $('#bulletAjaxingDragging').hide();
                        self.ajaxing = false;
                    }
                }
            });
        }
        ajaxUploadFile(fd) {
            let self = this;
            self.ajaxing = true;
            const token = $('meta[name="csrf-token"]').attr('content');
            $.ajax({
                headers: { 'X-CSRF-TOKEN': token },
                url: '/ajax/upload_file',
                type: 'post',
                data: fd,
                dataType: 'json',
                cache: false,
                processData: false,
                contentType: false,
                beforeSend: function () {
                    // 実行中画面
                    $('#bulletAjaxingDragging').show();
                },
                success: function (data) {
                    // console.log(data);
                    self.viewUploadedFile(data);
                },
                complete: function () {
                    // 実行中画面を消す
                    $('#bulletAjaxingDragging').hide();
                    self.ajaxing = false;
                }
            });
        }
        ajaxUploadFiles(fd, flagFinish) {
            let self = this;
            self.ajaxing = true;
            const token = $('meta[name="csrf-token"]').attr('content');
            $.ajax({
                headers: { 'X-CSRF-TOKEN': token },
                url: '/ajax/upload_file',
                type: 'post',
                data: fd,
                dataType: 'json',
                cache: false,
                processData: false,
                contentType: false,
                beforeSend: function () {
                    // 実行中画面
                    $('#bulletAjaxingDragging').show();
                },
                success: function (data) {
                    // console.log(data);
                    self.viewUploadedFiles(data);
                },
                complete: function () {
                    if (flagFinish) {
                        // 実行中画面を消す
                        $('#bulletAjaxingDragging').hide();
                        self.ajaxing = false;
                    }
                }
            });
        }
        // 一枚の画像に更新
        viewUploadedImage(data) {
            $('.bulletUploadImage[data-name="' + this.name + '"]').html(data.view);
            let uploadIds = [data.upload_id];
            let filepathes = [data.path + '/' + data.filename];
            this.refreshPostDatas(uploadIds, filepathes);
        }
        // 複数画像を追加
        viewUploadedImages(data) {
            $('.bulletUploadImage[data-name="' + this.name + '"]').append(data.view);
            let uploadIds = this.getUploadIds();
            let filepathes = this.getFilepathes();
            uploadIds.push(data.upload_id);
            filepathes.push(data.path + '/' + data.filename);
            this.refreshPostDatas(uploadIds, filepathes);
        }
        // 一枚のファイル名に更新
        viewUploadedFile(data) {
            $('.bulletUploadFile[data-name="' + this.name + '"]').html(data.view);
            let uploadIds = [data.upload_id];
            let filepathes = [data.path + '/' + data.filename];
            this.refreshPostDatas(uploadIds, filepathes);
        }
        // 複数のファイル名を追加
        viewUploadedFiles(data) {
            $('.bulletUploadFile[data-name="' + this.name + '"]').append(data.view);
            let uploadIds = this.getUploadIds();
            let filepathes = this.getFilepathes();
            uploadIds.push(data.upload_id);
            filepathes.push(data.path + '/' + data.filename);
            this.refreshPostDatas(uploadIds, filepathes);
        }
        getUploadIds() {
            let self = this;
            let ary = [];
            const selector = '.bulletUploadIds[data-name="' + self.name + '"] input';
            for (let i = 0; i < $(selector).length; i++) {
                ary.push($(selector).eq(i).val());
            }
            return ary;
        }
        getFilepathes() {
            let self = this;
            let ary = [];
            const selector = '.bulletFilepathes[data-name="' + self.name + '"] input';
            for (let i = 0; i < $(selector).length; i++) {
                ary.push($(selector).eq(i).val());
            }
            return ary;
        }
        // Formで送る input:hidden[name="upload_ids[]"] と input:hidden[name="filepathes[]"] を更新
        refreshPostDatas(uploadIds, filepathes) {
            let self = this;
            $('.bulletUploadIds[data-name="' + self.name + '"]').html('');
            $('.bulletFilepathes[data-name="' + self.name + '"]').html('');
            uploadIds.forEach(function (uploadId, i) {
                let body1 = '<input type="hidden" name="upload_ids[' + self.name + '][]" value="' + uploadId + '" class="bulletUploadId" data-id="' + uploadId + '">';
                $('.bulletUploadIds[data-name="' + self.name + '"]').append(body1);
                let body2 = '<input type="hidden" name="filepathes[' + self.name + '][]" value="' + filepathes[i] + '" class="bulletRemoveFilepath" data-id="' + uploadId + '">';
                $('.bulletFilepathes[data-name="' + self.name + '"]').append(body2);
            });
        }
        removeImage(targetName, uploadId) {
            const wrapImage = '.bulletUploadImage[data-name="' + targetName + '"]';
            const wrapUploadId = '.bulletUploadIds[data-name="' + targetName + '"]';
            const wrapFilepath = '.bulletFilepathes[data-name="' + targetName + '"]';
            $(wrapImage + ' .bulletRemoveImage[data-id="' + uploadId + '"]').remove();
            $(wrapUploadId + ' .bulletUploadId[data-id="' + uploadId + '"]').remove();
            $(wrapFilepath + ' .bulletFilepath[data-id="' + uploadId + '"]').remove();
        }
        removeFile(targetName, uploadId) {
            const wrapFile = '.bulletUploadFile[data-name="' + targetName + '"]';
            const wrapUploadId = '.bulletUploadIds[data-name="' + targetName + '"]';
            const wrapFilepath = '.bulletFilepathes[data-name="' + targetName + '"]';
            $(wrapFile + ' .bulletRemoveFile[data-id="' + uploadId + '"]').remove();
            $(wrapUploadId + ' .bulletUploadId[data-id="' + uploadId + '"]').remove();
            $(wrapFilepath + ' .bulletFilepath[data-id="' + uploadId + '"]').remove();
        }
    }
    Upload.MyUpload = MyUpload;
})(Upload || (Upload = {}));
exports.default = Upload;
