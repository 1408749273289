'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
exports.SmoothScroll = void 0;
const $ = require("jquery");
const func_1 = require("../func");
class SmoothScroll {
    constructor() {
        this.scrollPlusHeight = 0;
        this.scrollSpeed = 550;
        this.scrollEasing = "swing";
        const self = this;
        window.addEventListener('load', () => {
            this.getScrollPlusHeight();
        });
        $('a').click(function () {
            const href = $(this).attr("href");
            if (self.isAnchor(href)) {
                self.scrollToAnchor(href);
                return false;
            }
        });
        // URLがアンカーリンク付きだった時
        window.addEventListener('load', () => {
            const url = location.href;
            if (this.isAnchor(url)) {
                self.scrollToAnchor(url, 0);
                return false;
            }
        });
    }
    getScrollPlusHeight() {
        if (func_1.default.isTab() || func_1.default.isPC()) {
            const sel = '.paramScrollPlus';
            for (let i = 0; i < $(sel).length; i++) {
                const elm = $(sel).eq(i);
                this.scrollPlusHeight += elm.outerHeight();
            }
        }
        else if (func_1.default.isSP()) {
            this.scrollPlusHeight = $('#paramHeaderArea').height();
        }
    }
    isAnchor(href) {
        return href.indexOf('#') != -1;
    }
    getAnchorName(href) {
        const index = href.indexOf('#');
        return href.slice(index + 1);
    }
    scrollToAnchor(href, speed = this.scrollSpeed) {
        const anchor = this.getAnchorName(href);
        const position = $('#' + anchor).offset().top - this.scrollPlusHeight;
        $("html, body").animate({ scrollTop: position }, speed, this.scrollEasing);
    }
}
exports.SmoothScroll = SmoothScroll;
exports.default = SmoothScroll;
