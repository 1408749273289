'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const $ = require("jquery");
const func_1 = require("../func");
var Order;
(function (Order) {
    class MyOrder {
        constructor(ajaxing = false, colorId = '', bydesignCount = 0, adjust = {
            maxCount: 0,
            unitPrice: 0,
            minPrice: 0,
            maxPrice: 0,
        }, viewed = {
            print: false,
            face: false,
            color: false,
            paper: false,
            film: false,
            bydesignCount: false,
        }, itemPrintPrice = 0, itemLaminatePrice = 0, sizes = {}) {
            this.ajaxing = ajaxing;
            this.colorId = colorId;
            this.bydesignCount = bydesignCount;
            this.adjust = adjust;
            this.viewed = viewed;
            this.itemPrintPrice = itemPrintPrice;
            this.itemLaminatePrice = itemLaminatePrice;
            this.sizes = sizes;
            let self = this;
            this.sizes = MyOrder.getSizes();
            // 大判・小判・小判特殊
            $('.trigChangeOrderType').click(function () {
                const type = $(this).data('type');
                MyOrder.changeOrderType(type);
                MyOrder.slideSizeButtons(type);
            });
            // 大判・小判・小判特殊（スライドボタン）
            $('.trigSizeArrow').click(function () {
                const arrow = $(this).data("arrow");
                const currentType = self.getCurrentSizeType();
                const targetType = self.getArrowedType(currentType, arrow);
                MyOrder.slideSizeButtons(targetType);
                MyOrder.changeOrderType(targetType);
            });
            // 非定型サイズ入力欄
            $('.trigSizeInput').on('keyup', () => {
                const size = MyOrder.getSizeInputLength($('.trigSizeInput').eq(0), $('.trigSizeInput').eq(1));
                let printSizeId = null;
                if (MyOrder.validateCustomSize(size)) {
                    printSizeId = MyOrder.findPrintSize(this.sizes, size);
                }
                if (!printSizeId) {
                    $('.bulletSizeInput').attr('data-enable', 0).attr('data-select', 0);
                }
                else {
                    $('.bulletSizeInput').attr('data-enable', 1);
                }
            });
            // 注文ページ サイズ変更
            $('.trigSelectSize').click(function () {
                // const category = $(this).data('category');
                const sizeId = $(this).data('size');
                if (sizeId != "custom") {
                    // MyOrder.changeTypeImage(category);
                    MyOrder.setSelectedButton('size', sizeId);
                    // MyOrder.changeSizeName(size);
                    MyOrder.changeLength(null, self.sizes[sizeId]);
                    MyOrder.setSize(sizeId);
                    MyOrder.setCustomSize(null);
                    self.viewNextButton('size', sizeId);
                }
                else {
                    // カスタムサイズの場合の処理
                    const entrySizes = MyOrder.getSizeInputLength($('.custom-short-length'), $('.custom-long-length'));
                    const validate = MyOrder.validateCustomSize(entrySizes);
                    if (!MyOrder.validateCustomSize(entrySizes)) {
                        alert("サイズを正しく入力してください");
                        return false;
                    }
                    const printSizeId = MyOrder.findPrintSize(self.sizes, entrySizes);
                    if (!printSizeId) {
                        return alert("ご指定のサイズは対応ができません");
                    }
                    MyOrder.setSelectedButton('size', sizeId);
                    MyOrder.setSize(printSizeId);
                    MyOrder.changeLength(entrySizes, self.sizes[printSizeId]);
                    MyOrder.setCustomSize(entrySizes);
                    self.viewNextButton('size', sizeId);
                }
            });
            // 印刷項目選択
            $('.trigSelectPrint').click(function () {
                const order = $(this).data('order');
                const val = $(this).data('val');
                MyOrder.setSelectedButton(order, val);
                self.viewNextButton(order, val);
                MyOrder.setSelectedColor(order, val);
            });
            // ラミネートフィルム選択
            $('.trigSelectFilm').click(function () {
                const order = $(this).data('order');
                const val = $(this).data('val');
                MyOrder.setSelectedButton(order, val);
                MyOrder.setSelectedFilm(order, val);
            });
            // 数量変更
            $(document).on('click', '.trigCount', function () {
                const name = $(this).attr('data-name');
                const vector = $(this).attr('data-vector');
                const val = MyOrder.getNewCount(name, vector);
                MyOrder.setNewCount(name, val);
                // 調整費計算
                if (name == 'designs_count') {
                    const designsCount = Number($('.paramDesignsCount').val());
                    const adjustPrice = self.getCalcedAdjustPrice(designsCount);
                    const printPrice = self.getCalcedPrintPrice(designsCount);
                    const laminatePrice = self.getCalcedLaminatePrice(designsCount);
                    const subtotalPrice = Math.floor(printPrice) + Math.floor(laminatePrice) + adjustPrice;
                    self.viewPrintPrice(printPrice);
                    self.viewLaminatePrice(laminatePrice);
                    self.viewAdjustPrice(adjustPrice);
                    self.viewSubtotalPrice(subtotalPrice);
                }
            });
            // 数量入力欄クリックでカーソルを入力欄の末尾に移動
            $(document).on('click', '#trigCount', function () {
                const elm = document.getElementById('trigCount');
                elm.setSelectionRange(100, 100);
            });
            // 調整費計算（直接入力）
            $('.paramDesignsCount').on('change keyup', function () {
                const designsCount = Number($(this).val());
                const adjustPrice = self.getCalcedAdjustPrice(designsCount);
                const printPrice = self.getCalcedPrintPrice(designsCount);
                const laminatePrice = self.getCalcedLaminatePrice(designsCount);
                const subtotalPrice = Math.floor(printPrice) + Math.floor(laminatePrice) + adjustPrice;
                self.viewPrintPrice(printPrice);
                self.viewLaminatePrice(laminatePrice);
                self.viewAdjustPrice(adjustPrice);
                self.viewSubtotalPrice(subtotalPrice);
            });
            // select_item 読込時
            if ($('#ThisIsSelectItemPage').length > 0) {
                // サイズ変更
                const sizeId = MyOrder.getSize();
                MyOrder.changeLength(null, this.sizes[sizeId]);
                MyOrder.setSelectedButton('size', sizeId);
                // サイスボタン横スライド位置
                const currentType = self.getCurrentSizeType();
                MyOrder.slideSizeButtons(currentType, 50);
                // 初回 印刷有無選択肢を表示
                $('.bulletBox[data-box="print"]').delay(1000).slideDown(200);
                this.viewed.print = true;
            }
            // select_item（edit） 読込時
            if ($('#ThisIsEditItemPage').length > 0) {
                self.initializeViews();
            }
            // conf_item 読込時
            if ($('#ThisIsConfItemPage').length > 0) {
                this.itemPrintPrice = Number($('#paramItemPrintPrice').val());
                this.itemLaminatePrice = Number($('#paramItemLaminatePrice').val());
                // 調整費計算
                self.setAdjustPriceParameters();
                const designsCount = Number($('.paramDesignsCount').val());
                const adjustPrice = self.getCalcedAdjustPrice(designsCount);
                const printPrice = self.getCalcedPrintPrice(designsCount);
                const laminatePrice = self.getCalcedLaminatePrice(designsCount);
                const subtotalPrice = Math.floor(printPrice) + Math.floor(laminatePrice) + adjustPrice;
                self.viewPrintPrice(printPrice);
                self.viewLaminatePrice(laminatePrice);
                self.viewAdjustPrice(adjustPrice);
                self.viewSubtotalPrice(subtotalPrice);
            }
            // 料金表ページ サイズ変更
            $('.trigSelectPriceSize').click(function () {
                const sizeId = $(this).data('size');
                if (sizeId != "custom") {
                    // カスタムサイズでない場合の処理
                    const sizes = MyOrder.getSizes();
                    MyOrder.setSelectedPriceSizeButton(sizeId);
                    MyOrder.setSize(sizeId);
                    MyOrder.setCustomSize(null);
                    MyOrder.changeLength(null, self.sizes[sizeId]);
                    //changeLength
                    $('.bulletChangePriceList').hide();
                    $('.bulletChangePriceList[data-size="' + sizeId + '"][data-custom=0]').fadeIn();
                }
                else {
                    // カスタムサイズの場合の処理
                    const entrySizes = MyOrder.getSizeInputLength($('.custom-short-length'), $('.custom-long-length'));
                    if (!MyOrder.validateCustomSize(entrySizes)) {
                        return alert("サイズを正しく入力してください");
                    }
                    const printSizeId = MyOrder.findPrintSize(self.sizes, entrySizes);
                    if (!printSizeId) {
                        return alert("ご指定のサイズは対応ができません");
                    }
                    MyOrder.setSelectedPriceSizeButton(sizeId);
                    MyOrder.setSize(printSizeId);
                    MyOrder.setCustomSize(entrySizes);
                    MyOrder.changeLength(entrySizes, self.sizes[printSizeId]);
                    $('.bulletChangePriceList').hide();
                    $('.bulletChangePriceList[data-size="' + printSizeId + '"][data-custom=1]').fadeIn();
                }
            });
            // 非定型の注文で価格表の金額を押した時、入力したサイズを送信データに書き込む
            // $('.bulletChangePriceList[data-custom=1] .trigClickToOrder').submit(function () {
            //     const $form = $(this)
            //     const size = {
            //         short_length: Number($('.custom-short-length').val()),
            //         long_length: Number($('.custom-long-length').val()),
            //     }
            //     $form.children('[name=custom_short_length]').val(size.short_length)
            //     $form.children('[name=custom_long_length]').val(size.long_length)
            // });
            // price 読込時
            if ($('#ThisIsPricingPage').length > 0) {
                // サイズボタン横スライド位置
                // const sizeId = 'A4';
                // MyOrder.setSelectedPriceSizeButton(sizeId);
                const currentType = 'small';
                MyOrder.slideSizeButtons(currentType, 50);
                // $('.bulletChangePriceList').hide();
                // $('.bulletChangePriceList[data-size="' + sizeId + '"]').fadeIn();
            }
        }
        static getSizes() {
            const elem = document.getElementById("paramSizes");
            if (elem == undefined) {
                return false;
            }
            return JSON.parse(elem.textContent);
        }
        initializeViews() {
            const sizeId = MyOrder.getSize();
            const filmId = MyOrder.getFilm();
            const colorId = MyOrder.getColor();
            //
            if (filmId) {
                switch (filmId) {
                    case 'N':
                        MyOrder.setSelectedButton('film', 'normal');
                        break;
                    case 'H':
                        MyOrder.setSelectedButton('film', 'high');
                        break;
                    case 'U':
                        MyOrder.setSelectedButton('film', 'ultra');
                        break;
                }
            }
            if (sizeId) {
                // サイズ
                const sizes = MyOrder.getSizes();
                const category = func_1.default.getSizeCategory(sizeId);
                const type = func_1.default.getSizeType(sizeId);
                // MyOrder.changeTypeImage( category );
                // MyOrder.changeSizeName(size);
                if (!MyOrder.isCustomSize()) {
                    MyOrder.changeLength(null, sizes[sizeId]);
                    MyOrder.slideSizeButtons(type);
                    MyOrder.changeOrderType(type);
                    MyOrder.setSelectedButton('size', sizeId);
                }
                else {
                    const customSize = MyOrder.getCustomSize();
                    MyOrder.changeLength(customSize, sizes[sizeId]);
                    MyOrder.slideSizeButtons('custom');
                    MyOrder.changeOrderType('custom');
                    MyOrder.setSelectedButton('size', 'custom');
                    $('.custom-short-length').val(customSize.short_length);
                    $('.custom-long-length').val(customSize.long_length);
                }
                // 印刷
                $('.bulletBox[data-box="print"]').slideDown(200);
                this.viewed.print = true;
                switch (colorId) {
                    case 'CL44':
                    case 'CL41':
                    case 'CL11':
                        MyOrder.setSelectedButton('print', 1);
                        MyOrder.setSelectedButton('face', 2);
                        MyOrder.setSelectedButton('color', colorId);
                        $('.bulletBox[data-box="face"]').slideDown(200);
                        this.viewed.face = true;
                        $('.bulletBox[data-box="color"]').slideDown(200);
                        $('.bulletColor[data-val="CL44"]').show();
                        $('.bulletColor[data-val="CL41"]').show();
                        $('.bulletColor[data-val="CL11"]').show();
                        $('.bulletColor[data-val="CL40"]').hide();
                        $('.bulletColor[data-val="CL10"]').hide();
                        this.viewed.color = true;
                        $('.bulletBox[data-box="paper"]').slideDown(200);
                        this.viewed.paper = true;
                        break;
                    case 'CL40':
                    case 'CL10':
                        MyOrder.setSelectedButton('print', 1);
                        MyOrder.setSelectedButton('face', 1);
                        MyOrder.setSelectedButton('color', colorId);
                        $('.bulletBox[data-box="face"]').slideDown(200);
                        this.viewed.face = true;
                        $('.bulletBox[data-box="color"]').slideDown(200);
                        $('.bulletColor[data-val="CL40"]').show();
                        $('.bulletColor[data-val="CL10"]').show();
                        $('.bulletColor[data-val="CL44"]').hide();
                        $('.bulletColor[data-val="CL41"]').hide();
                        $('.bulletColor[data-val="CL11"]').hide();
                        this.viewed.color = true;
                        $('.bulletBox[data-box="paper"]').slideDown(200);
                        this.viewed.paper = true;
                        break;
                    default:
                        MyOrder.setSelectedButton('print', 0);
                        break;
                }
                // フィルム
                $('.bulletBox[data-box="film"]').slideDown(200);
                this.viewed.film = true;
                // 枚数
                $('.bulletBox[data-box="bydesign_count"]').slideDown(200);
                this.viewed.bydesignCount = true;
                MyOrder.enableSubmit();
            }
        }
        static enableSubmit() {
            $('#bulletSubmit').prop('disabled', false).attr('data-enable', 1);
        }
        static changeTypeImage(category) {
            $('.bulletSizeTypeImage').attr("data-category", category);
        }
        static changeSizeName(size) {
            $('.bulletSizeTypeImageBody').html(size.name);
        }
        static changeLength(entrySizes, packableSizeObj) {
            let body = '';
            body += packableSizeObj.name;
            if (entrySizes != null) {
                if (entrySizes.short_length != packableSizeObj.short_length || entrySizes.long_length != packableSizeObj) {
                    body += '非定型';
                }
                body += '（' + entrySizes.short_length + ' x ' + entrySizes.long_length + ' mm）';
            }
            else {
                body += '（' + packableSizeObj.short_length + ' x ' + packableSizeObj.long_length + ' mm）';
            }
            $('#bulletSizeLength').html(body);
        }
        static getSize() {
            return $('#paramSizeId').val();
        }
        static setSize(sizeId) {
            $('#paramSizeId').val(sizeId);
        }
        static getColor() {
            return $('#paramColorId').val();
        }
        static setColor(sizeId) {
            $('#paramColorId').val(sizeId);
        }
        static getFilm() {
            return $('#paramFilmId').val();
        }
        static setFilm(filmId) {
            $('#paramFilmId').val(filmId);
        }
        static setSelectedColor(order, val) {
            if (order == 'print') {
                if (val == '0') {
                    MyOrder.setColor('CL00');
                }
                else {
                    const colorId = MyOrder.getCurrentColor();
                    if (colorId) {
                        MyOrder.setColor(colorId);
                    }
                }
            }
            else if (order == 'color') {
                MyOrder.setColor(val);
            }
        }
        static setSelectedFilm(order, val) {
            if (order == 'film') {
                if (val == 'normal') {
                    MyOrder.setFilm('N');
                }
                else if (val == 'high') {
                    MyOrder.setFilm('H');
                }
                else if (val == 'ultra') {
                    MyOrder.setFilm('U');
                }
            }
        }
        viewNextButton(order, val) {
            switch (order) {
                case 'size': // サイズ
                    $('.bulletBox[data-box="print"]').slideDown(200);
                    $('.bulletFace[data-val="1"]').show();
                    $('.bulletFace[data-val="2"]').hide();
                    $('.bulletColor[data-val="CL44"]').hide();
                    $('.bulletColor[data-val="CL41"]').hide();
                    $('.bulletColor[data-val="CL11"]').hide();
                    $('.bulletColor[data-val="CL40"]').show();
                    $('.bulletColor[data-val="CL10"]').show();
                    $('.bulletFilm[data-val="normal"]').show();
                    $('.bulletFilm[data-val="high"]').hide();
                    $('.bulletFilm[data-val="ultra"]').hide();
                    if (MyOrder.getSize() == 'A1' || MyOrder.getSize() == 'A2' || MyOrder.getSize() == 'B2' || MyOrder.getSize() == 'B3' || MyOrder.getSize() == 'NM') {
                        $('.bulletFilm[data-val="high"]').show();
                    }
                    else if (MyOrder.getSize() == 'CD') {
                        //
                    }
                    else if (MyOrder.getSize() == 'A4' || MyOrder.getSize() == 'A3' || MyOrder.getSize() == 'B4' || MyOrder.getSize() == 'B5') {
                        $('.bulletFilm[data-val="high"]').show();
                        $('.bulletFilm[data-val="ultra"]').show();
                    }
                    break;
                case 'print': // 印刷 有無
                    if (val == "1") {
                        if (this.viewed.face) {
                            $('.bulletBox[data-box="face"]').slideDown(200);
                            $('.bulletBox[data-box="color"]').slideDown(200);
                            $('.bulletBox[data-box="paper"]').slideDown(200);
                        }
                        else {
                            $('.bulletBox[data-box="face"]').slideDown(200);
                            this.viewed.face = true;
                        }
                        if (func_1.default.isLarge(MyOrder.getSize())) {
                            $('.bulletFace[data-val="1"]').show();
                            $('.bulletFace[data-val="2"]').hide();
                        }
                        else {
                            $('.bulletFace[data-val="1"]').show();
                            $('.bulletFace[data-val="2"]').show();
                        }
                    }
                    else if (val == "0") {
                        $('.bulletBox[data-box="face"]').hide();
                        $('.bulletBox[data-box="color"]').hide();
                        $('.bulletBox[data-box="paper"]').hide();
                        if (!this.viewed.film) {
                            $('.bulletBox[data-box="film"]').slideDown(200);
                            this.viewed.film = true;
                        }
                        if (!this.viewed.bydesignCount) {
                            $('.bulletBox[data-box="bydesign_count"]').slideDown(200);
                            this.viewed.bydesignCount = true;
                            MyOrder.enableSubmit();
                        }
                        // $('.bulletBydesignCount').focus();
                    }
                    break;
                case 'face': // 両面 or 片面
                    if (val == "2") {
                        $('.bulletColor[data-val="CL44"]').show();
                        $('.bulletColor[data-val="CL41"]').show();
                        $('.bulletColor[data-val="CL11"]').show();
                        $('.bulletColor[data-val="CL40"]').hide();
                        $('.bulletColor[data-val="CL10"]').hide();
                    }
                    else if (val == "1") {
                        $('.bulletColor[data-val="CL40"]').show();
                        $('.bulletColor[data-val="CL10"]').show();
                        $('.bulletColor[data-val="CL44"]').hide();
                        $('.bulletColor[data-val="CL41"]').hide();
                        $('.bulletColor[data-val="CL11"]').hide();
                    }
                    if (!this.viewed.color) {
                        $('.bulletBox[data-box="color"]').slideDown(200);
                        this.viewed.color = true;
                    }
                    break;
                case 'color': // 色数
                    if (!this.viewed.paper) {
                        $('.bulletBox[data-box="paper"]').slideDown(200);
                        this.viewed.paper = true;
                    }
                    if (!this.viewed.film) {
                        $('.bulletBox[data-box="film"]').slideDown(200);
                        this.viewed.film = true;
                    }
                    if (!this.viewed.bydesignCount) {
                        $('.bulletBox[data-box="bydesign_count"]').slideDown(200);
                        this.viewed.bydesignCount = true;
                        MyOrder.enableSubmit();
                    }
                    MyOrder.changeColorValue(val);
                    break;
            }
        }
        static getSizeInputLength(shortElem, longElem) {
            let size = {
                short_length: Number(shortElem.val()),
                long_length: Number(longElem.val())
            };
            if (size.short_length > size.long_length) {
                const long = size.short_length;
                size.short_length = size.long_length;
                size.long_length = long;
            }
            return size;
        }
        static changeColorValue(code) {
            $('#paramColorId').val(code);
        }
        static getNewCount(name, vector) {
            const input = $('.bulletCount[data-name="' + name + '"]');
            let val = Number(input.val());
            if (vector == "-1") {
                val--;
                if (val < 1) {
                    val = 1;
                }
            }
            else {
                val++;
            }
            return val;
        }
        static setNewCount(name, val) {
            $('.bulletCount[data-name="' + name + '"]').val(val);
        }
        static changeOrderType(type) {
            $('.trigChangeOrderType').attr("data-select", 0);
            $('.trigChangeOrderType[data-type="' + type + '"]').attr("data-select", 1);
        }
        static setSelectedButton(idName, val) {
            switch (idName) {
                case 'size':
                    $('.trigSelectSize').attr("data-select", 0);
                    $('.trigSelectSize[data-size="' + val + '"]').attr("data-select", 1);
                    break;
                case 'print':
                    $('.bulletPrint').attr("data-select", 0);
                    $('.bulletPrint[data-val="' + val + '"]').attr("data-select", 1);
                    break;
                case 'face':
                    $('.bulletFace').attr("data-select", 0);
                    $('.bulletFace[data-val="' + val + '"]').attr("data-select", 1);
                    break;
                case 'color':
                    $('.bulletColor').attr("data-select", 0);
                    $('.bulletColor[data-val="' + val + '"]').attr("data-select", 1);
                    break;
                case 'film':
                    $('.bulletFilm').attr("data-select", 0);
                    $('.bulletFilm[data-val="' + val + '"]').attr("data-select", 1);
                    break;
            }
        }
        static setSelectedPriceSizeButton(val) {
            $('.trigSelectPriceSize').attr("data-select", 0);
            $('.trigSelectPriceSize[data-size="' + val + '"]').attr("data-select", 1);
        }
        static slideSizeButtons(type, duration = 500) {
            const box = $('.bulletOrderSizeBox[data-type="' + type + '"]');
            let boxLeft = box.position().left;
            const area = document.getElementsByClassName('bulletChangeOrderType')[0];
            boxLeft += area.scrollLeft;
            const $area = $('.bulletChangeOrderType');
            const marginLeftWidth = Number($area.css("marginLeft").replace("px", ""));
            boxLeft -= marginLeftWidth;
            $area.animate({ scrollLeft: boxLeft }, { duration: duration, easing: "swing" });
        }
        static validateCustomSize(size) {
            if (size.short_length.isNaN || size.long_length.isNaN) {
                return false;
            }
            if (size.short_length < 55 || size.long_length < 55) {
                return false;
            }
            return true;
        }
        getCurrentSizeType() {
            let type = "";
            const box = $('.trigChangeOrderType');
            for (let i = 0; i < box.length; i++) {
                const select = box.eq(i).attr("data-select");
                if (select == "1") {
                    type = box.eq(i).attr("data-type");
                    break;
                }
            }
            return type;
        }
        static getCurrentColor() {
            let type = "";
            const box = $('.bulletColor');
            for (let i = 0; i < box.length; i++) {
                const select = box.eq(i).attr("data-select");
                if (select == "1") {
                    type = box.eq(i).attr("data-val");
                    break;
                }
            }
            return type;
        }
        getArrowedType(currentType, arrow) {
            let targetType = "";
            if (arrow == 'left') {
                switch (currentType) {
                    case 'large':
                        targetType = 'special';
                        break;
                    case 'small':
                        targetType = 'large';
                        break;
                    case 'special':
                        targetType = 'small';
                        break;
                }
            }
            else if (arrow == 'right') {
                switch (currentType) {
                    case 'large':
                        targetType = 'small';
                        break;
                    case 'small':
                        targetType = 'special';
                        break;
                    case 'special':
                        targetType = 'large';
                        break;
                }
            }
            return targetType;
        }
        setAdjustPriceParameters() {
            this.colorId = $('.paramColorId').val();
            this.bydesignCount = Number($('.paramBydesignCount').val());
            this.adjust = {
                maxCount: Number($('.paramAdjustMaxCount').val()),
                unitPrice: Number($('.paramAdjustUnitPrice').val()),
                minPrice: Number($('.paramAdjustMinPrice').val()),
                maxPrice: Number($('.paramAdjustMaxPrice').val()),
            };
            // this.flagLoadAdjust = true;
        }
        getCalcedPrintPrice(designsCount) {
            let price = this.itemPrintPrice * designsCount;
            price = Math.floor(price * 10) / 10;
            return price;
        }
        getCalcedLaminatePrice(designsCount) {
            let price = this.itemLaminatePrice * designsCount;
            price = Math.floor(price * 10) / 10;
            return price;
        }
        viewPrintPrice(price) {
            // $('#bulletPrintPrice').html(Func.numberFormat(price));
            $('#bulletPrintPrice').html(func_1.default.formatDecimalToText(price));
        }
        viewLaminatePrice(price) {
            $('#bulletLaminatePrice').html(func_1.default.formatDecimalToText(price));
        }
        viewAdjustPrice(price) {
            $('#bulletAdjustPrice').html(func_1.default.numberFormat(price));
        }
        viewSubtotalPrice(price) {
            $('#bulletSubtotalPrice').html(func_1.default.numberFormat(price));
        }
        getCalcedAdjustPrice(designsCount) {
            let price = 0;
            let byunitCount = 0;
            let unitCount = 0;
            if (!this.colorId || this.colorId == 'CL00') {
                byunitCount = designsCount * this.bydesignCount;
                unitCount = 1;
            }
            else {
                byunitCount = this.bydesignCount;
                unitCount = designsCount;
            }
            if (byunitCount <= this.adjust.maxCount) {
                price = this.adjust.unitPrice * unitCount;
                if (price > this.adjust.maxPrice) {
                    price = this.adjust.maxPrice;
                }
                else if (price < this.adjust.minPrice) {
                    price = this.adjust.minPrice;
                }
            }
            return price;
        }
        static findPrintSize(sizes, size) {
            let minSurface = Number.MAX_VALUE;
            let minSizeId = null;
            for (const sizeId in sizes) {
                if (size.short_length <= sizes[sizeId].short_length && size.long_length <= sizes[sizeId].long_length) {
                    const surface = sizes[sizeId].short_length * sizes[sizeId].long_length;
                    if (surface < minSurface) {
                        minSurface = surface;
                        minSizeId = sizeId;
                    }
                }
            }
            return minSizeId;
        }
        static setCustomSize(entrySizes) {
            if (entrySizes) {
                $('input.paramCustomShortLength').val(entrySizes.short_length);
                $('input.paramCustomLongLength').val(entrySizes.long_length);
            }
            else {
                $('input.paramCustomShortLength').val(-1);
                $('input.paramCustomLongLength').val(-1);
            }
        }
        static getCustomSize() {
            const short_length = Number($('input.paramCustomShortLength').eq(0).val());
            const long_length = Number($('input.paramCustomLongLength').eq(0).val());
            return { short_length, long_length };
        }
        static isCustomSize() {
            const size = MyOrder.getCustomSize();
            return size.short_length != -1;
        }
    }
    Order.MyOrder = MyOrder;
})(Order || (Order = {}));
exports.default = Order;
