'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
exports.Fukidashi = void 0;
const $ = require("jquery");
class Fukidashi {
    constructor() {
        const self = this;
        $('.trigViewBox').click(function () {
            const viewbox = $(this).attr('data-viewbox');
            const num = $(this).attr('data-num');
            let fukidashi = $('.paramViewBox[data-viewbox="' + viewbox + '"]').clone();
            if (num) {
                fukidashi = fukidashi.attr('data-num', num);
                fukidashi.find('.bulletRemoveViewBox').attr('data-num', num);
                $('.bulletViewBox[data-num="' + num + '"]').html('').append(fukidashi);
            }
            else {
                $('.bulletViewBox').html('').append(fukidashi);
            }
        });
        $(document).on('click', '.bulletRemoveViewBox', function () {
            const num = $(this).attr('data-num');
            if (num) {
                $('.bulletViewBox[data-num="' + num + '"]').html('');
            }
            else {
                $('.bulletViewBox').html('');
            }
        });
    }
}
exports.Fukidashi = Fukidashi;
exports.default = Fukidashi;
